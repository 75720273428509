<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import HaButton from '../HaButton/HaButton.vue'
import useHeaderPublic from './useHeaderPublic'
import Logo from './Partials/Logo/Logo.vue'
import SearchBtn from './Partials/Search/SearchBtn/SearchBtn.vue'
import SearchForm from './Partials/Search/SearchForm/SearchForm.vue'
import Dropdown from './Partials/Dropdown/Dropdown.vue'
import HeaderListItem from './Partials/HeaderListitem/HeaderListItem.vue'
import SearchSuggestion from './Partials/Search/SearchSuggestion/SearchSuggestion.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSignOutAlt, faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import { organizationListItems, userListItems, connectedUsersList } from './constants.js'
import useHeaderSearch from './Partials/Search/useHeaderSearch'

const props = defineProps({
  userInfo: Object,
  searchInfo: Object
})

const {
  user,
  isOpenSearch,
  isConnected,
  isOpenMenu,
  toggleIsOpenMenu,
  toggleIsOpenSearch,
  segment,
  isLoadingUser,
  hasOrganizations,
  userOrganizations,
  closeMenuAndSearch
} = useHeaderPublic()

const { isSearchOnGoing } = useHeaderSearch()

const menuBtn = computed(() => (isOpenSearch.value || isOpenMenu.value ? 'Fermer' : 'Menu'))
const userName = computed(() => {
  if (user.value.firstName && user.value.lastName[0]) {
    return `${user.value.firstName} ${user.value.lastName[0]}.`
  }
  return 'Mon compte'
})

onMounted(() => {
  // Prevent Chrome's back navigation to keep menu's last state
  window.addEventListener('pageshow', () => closeMenuAndSearch())
})

onBeforeUnmount(() => {
  window.removeEventListener('pageshow', () => closeMenuAndSearch())
})

const userItemsLinks = computed(() => {
  if (isConnected.value) {
    const userListFiltered = userListItems().filter(item => !item.isAuth)
    return userListFiltered
  }
  return userListItems()
})

const organizationItemsLinks = computed(() => {
  if (isConnected.value) {
    const orgaListFiltered = organizationListItems().filter(item => !item.isAuth)
    return orgaListFiltered
  }
  return organizationListItems()
})

const authBaseUrl = process.env.NUXT_ENV_AUTH_FRONT
const urlAuth = computed(() => `${authBaseUrl}/inscription`)

const trackCallSignUp = () => {
  const trackInfo = {
    texte_cta: 'Inscrire mon association',
    link_origin: 'Header'
  }

  if (segment.value) {
    segment.value.track('CTA Signup', trackInfo)
  }

  window.location = urlAuth.value
}

const emit = defineEmits(['connect-request', 'disconnect'])

const trackCallLogin = () => {
  const trackInfo = {
    item_type: 'Button',
    item_label: 'Connexion'
  }

  if (segment.value) {
    segment.value.track('Header Item Clicked', trackInfo)
  }
  closeMenuAndSearch()
  emit('connect-request')
}

function toggleMenuOrSearch() {
  if (!isOpenMenu.value && !isOpenSearch.value) {
    toggleIsOpenMenu()
  } else {
    closeMenuAndSearch()
  }
}
</script>

<template>
  <header role="banner" :class="['header', { 'header--open': isOpenSearch || isOpenMenu }]">
    <div class="header__inner">
      <nav :class="['header__navbar', { 'header__navbar--open': isOpenSearch || isOpenMenu }]">
        <div class="nav__start">
          <Logo></Logo>
          <SearchBtn data-test="header-searchBtn" v-if="!isOpenMenu"></SearchBtn>
          <ha-button variant="outline" class="nav__menu" @click="toggleMenuOrSearch">
            {{ menuBtn }}
          </ha-button>
        </div>
        <div :class="['nav__end', { isOpenMenu }]" v-if="!isLoadingUser">
          <!-- espace particulier -->
          <Dropdown data-test="header-user-dropdown">
            <template #button>
              <ha-button
                v-if="!isConnected"
                @click.prevent="trackCallLogin"
                class="nav__end__connect-button nav__end__connect-button--mobile"
              >
                {{ $t('haComponents.haHeaderPublic.login') }}
              </ha-button>
              <ha-button
                v-if="!isConnected || !hasOrganizations"
                variant="outline"
                class="nav__end__signUp nav__end__signUp--mobile"
                @click.prevent="trackCallSignUp"
              >
                {{ $t('haComponents.haHeaderPublic.listItems.signUpOrga') }}
              </ha-button>
            </template>
            <template #title> Espace particulier </template>
            <template #content>
              <HeaderListItem v-for="item of userItemsLinks" :item="item" :key="item.id" />
            </template>
          </Dropdown>

          <!-- Espace Asso -->
          <Dropdown data-test="header-orga-dropdown">
            <template #title> Espace association </template>
            <template #content>
              <HeaderListItem v-for="item of organizationItemsLinks" :item="item" :key="item.id" />
              <li v-if="isConnected">
                <button
                  type="button"
                  class="nav__end__disconnect-button nav__end__disconnect-button--mobile"
                  @click="$emit('disconnect')"
                >
                  {{ $t('haComponents.haHeaderPublic.disconnect') }}
                  <font-awesome-icon class="result__icon" :icon="faSignOutAlt" aria-hidden="true" />
                </button>
              </li>
            </template>
            <template #cta>
              <ha-button
                v-if="!isConnected || !hasOrganizations"
                variant="outline"
                class="nav__end__signUp nav__end__signUp--desktop"
                @click.prevent="trackCallSignUp"
              >
                {{ $t('haComponents.haHeaderPublic.listItems.signUpOrga') }}
              </ha-button>
            </template>
          </Dropdown>

          <!-- login -->
          <div
            v-if="!isConnected"
            class="nav__end__connect-button nav__end__connect-button--desktop"
          >
            <ha-button @click.prevent="trackCallLogin" class="button__login">
              {{ $t('haComponents.haHeaderPublic.login') }}
            </ha-button>
          </div>

          <Dropdown
            v-else
            :dropdown-image="user.logo"
            :is-user-dropdown="true"
            :delegate-padding="true"
            class="nav__end__connected-dropdown"
            data-test="ha-dropdown-user"
          >
            <template #title>
              {{ userName }}
            </template>
            <template #content>
              <HeaderListItem v-for="item of connectedUsersList" :item="item" :key="item.id" />
              <ul v-if="hasOrganizations" class="nav__end__associations-wrapper">
                <b class="nav__end__associations-wrapper__title">
                  {{ $t('haComponents.haHeaderPublic.listItems.adminMyOrga') }}
                </b>
                <HeaderListItem
                  v-for="item of userOrganizations"
                  :item="item"
                  :is-association="true"
                  :key="item.id"
                />
                <HeaderListItem
                  :item="{
                    label: 'Ajouter une association',
                    icon: faPlusCircle,
                    href: urlAuth
                  }"
                  key="addNewAssociation"
                  :is-cta-association="true"
                />
              </ul>
              <li v-if="isConnected">
                <button
                  type="button"
                  class="nav__end__disconnect-button nav__end__disconnect-button--desktop"
                  @click="$emit('disconnect')"
                >
                  {{ $t('haComponents.haHeaderPublic.disconnect') }}
                  <font-awesome-icon class="result__icon" :icon="faSignOutAlt" aria-hidden="true" />
                </button>
              </li>
            </template>
          </Dropdown>
        </div>
      </nav>
      <div v-if="isOpenSearch" class="search">
        <SearchForm data-test="header-searchForm"></SearchForm>
        <SearchSuggestion v-if="!isSearchOnGoing"></SearchSuggestion>
      </div>
      <div
        :class="['header__overlay', { 'header__overlay--open': isOpenSearch }]"
        @click="closeMenuAndSearch"
      ></div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import './haHeaderPublic.scss';
</style>
